import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Navigation from '../Navigation/Navigation';
import { NavLink } from 'react-router-dom';
import { removeItemFromCartAction } from './CartRedux';
import { addCardSaveIframes } from '../Checkout/CheckoutRedux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Cart.scss';

export class Cart extends Component {
    static propTypes = {
        cart: PropTypes.any,
        removeFromCart: PropTypes.func
    }

    render() {
        const items = _.get(this.props, 'cart.items') || [];

        return (
            <div className="cart">
                <Navigation pageTitle="CART" />
                <div className="cart-items">
                    <div className="cart-body">
                        {this.renderItems(items)}
                    </div>
                    {this.renderTotal(items)}
                </div>
            </div>
        );
    }

    renderItems = (items) => {
        return _.map(items, (item) => {

            return (
                <div className="cart-item" key={item.item}>
                    <div className="item-info">
                        <div className={`cart-image-${item.item}`}></div>
                        <div className="item-details">
                            <span className="remove-item"><button className="item-button" onClick={() => this.props.removeFromCart(item.item)}>X</button></span>
                            <span>{item.description}</span>
                            <span>{item.quantity}</span>
                            <span>${item.price * item.quantity}.00</span>
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderTotal = () => {
        return (
            <div className="cart-total">
                <NavLink exact to="/items"><button>Back to Menu</button></NavLink>
                <NavLink exact to="/checkout"><button>Checkout</button></NavLink>
                <h3>Total: ${_.get(this, 'props.cart.amount', 0)}</h3>
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        cart: state.cart,
        iframes: state.iframes
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        removeFromCart: (item) => dispatch(removeItemFromCartAction(item)),
        addCardSaveIframes: (cardSaveIframes) => dispatch(addCardSaveIframes(cardSaveIframes))
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));