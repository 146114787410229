const urlConfig = require('@nexio/emvio-url-config');

const config = {
    local: {
        itemsUrl: 'https://api.nexiopaydev.com',
        mockRetailUrl: 'http://localhost:3099/v1'
    },
    test: {
        itemsUrl: 'TEST-retailIframeUrl',
        mockRetailUrl: 'TEST-RETAILURL'
    },
    development: {
        itemsUrl: 'https://api.nexiopaydev.com',
        mockRetailUrl: 'https://api.nexiopaydev.com/mockRetail/v1'
    },
    staging: {
        itemsUrl: 'https://api.nexiopaystg.com',
        mockRetailUrl: 'https://api.nexiopaystg.com/mockRetail/v1'
    },
    production: {
        itemsUrl: 'https://api.nexiopay.com',
        mockRetailUrl: 'https://api.nexiopay.com/mockRetail/v1'
    },
    sandbox: {
        itemsUrl: 'https://api.nexiopaysandbox.com',
        mockRetailUrl: 'https://api.nexiopaysandbox.com/mockRetail/v1'
    }
};
export default Object.assign(
    {},
    urlConfig[process.env.BUILD_ENV],
    config[process.env.BUILD_ENV]
);
