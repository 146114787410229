import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './Navigation.scss';
import logoImport from '../../images/demoCafe2.png';
import demoCafe from '../../images/demoCafe.png';

export default class Navigation extends Component {

    static propTypes = {
        pageTitle: PropTypes.string
    }

    render() {
        return (
            <>
                <div className="demo-cafe-nav">
                    <div>
                        <NavLink exact to="/"><img className="nav-logo" src={demoCafe} alt="Navigation Logo"></img></NavLink>
                    </div>
                    <div className="nav-links">
                        <div>
                            <NavLink exact to="/">Home</NavLink>
                        </div>
                        <div>
                            <NavLink exact to="/items">Menu</NavLink>
                        </div>
                        <div>
                            <NavLink exact to="/cart">Cart</NavLink>
                        </div>
                    </div>
                </div>
                <div className="banner">
                    <img className="banner-logo" src={logoImport} alt="Banner Logo"></img>
                    <h2 className="banner-title">{this.props.pageTitle}</h2>
                </div>
            </>
        );
    }
}