import {
    applyMiddleware,
    compose,
    createStore
} from 'redux';
import rootReducer from './rootReducer';
import { fetchMiddleware } from '@nexio/ui-redux';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [
    thunk,
    fetchMiddleware()
];

const store = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middleware))
);

export default store;
