import { cartReducer } from './App/Cart/CartRedux';
import { checkoutReducer } from './App/Checkout/CheckoutRedux';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    cart: cartReducer,
    checkout: checkoutReducer
});

export default (state, action) => {
    return rootReducer(state, action);
};
