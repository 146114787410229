import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addItemToCartAction } from '../Cart/CartRedux';
import { NavLink } from 'react-router-dom';
import './Items.scss';
import Navigation from '../Navigation/Navigation';

export class Items extends Component {

    static propTypes = {
        addToCart: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    render() {
        return (
            <div className="items">
                <Navigation pageTitle="OUR MENU" />
                <div className="items-body">
                    <div className="items-list">
                        <div className="item">
                            <div className="item-image-icecream"></div>
                            <div className="item-info">
                                <h2>Ice Cream</h2>
                                <h3>$10.00</h3>
                                <button className="icecream-button" onClick={() => this.props.addToCart(1)}>Add to Cart</button>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-image-tea"></div>
                            <div className="item-info">
                                <h2>Tea</h2>
                                <h3>$5.00</h3>
                                <button className="tea-button" onClick={() => this.props.addToCart(2)}>Add to Cart</button>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-image-pancakes"></div>
                            <div className="item-info">
                                <h2>Pancakes</h2>
                                <h3>$15.00</h3>
                                <button className="pancakes-button" onClick={() => this.props.addToCart(3)}>Add to Cart</button>
                            </div>
                        </div>
                    </div>
                    <div className="items-button">
                        <NavLink exact to="/cart"><button>View Cart</button></NavLink>
                    </div>
                </div>
            </div >
        );
    }
}

export function mapStateToProps(state) {
    return {
        items: state.items
    };
}

export default withRouter(connect(mapStateToProps, {
    addToCart: addItemToCartAction
})(Items));