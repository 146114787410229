/* eslint-disable no-case-declarations */
import _ from 'lodash';

export function cartReducer(state = {}, action = {}) {
    const id = _.get(action, 'item');
    const items = _.get(state, 'items', {});
    let amount;
    const itemMap = {
        1: {
            item: '1',
            description: 'Ice Cream',
            price: 10,
            type: 'sale'
        },
        2: {
            item: '2',
            description: 'Tea',
            price: 5,
            type: 'sale'
        },
        3: {
            item: '3',
            description: 'Pancakes',
            price: 15,
            type: 'sale'
        }
    };
    let newState;

    switch (action.type) {
        case 'ADD_TO_CART':
            const item = _.get(itemMap, id);
            if (items[id]) {
                items[id].quantity++;
            } else {
                items[id] = {
                    ...item,
                    quantity: 1
                };
            }
            amount = _.reduce(items, (total, item) => total + (item.price * item.quantity), 0);
            newState = {
                ...state,
                items,
                amount
            };

            return newState;

        case 'REMOVE_FROM_CART':
            let cart = _.get(state, 'items', {});
            if (cart[id].quantity > 1) {
                cart[id].quantity--;
            } else {
                delete cart[id];
            }
            amount = _.reduce(cart, (total, item) => total + (item.price * item.quantity), 0);

            newState = {
                ...state,
                items: cart,
                amount
            };

            return newState;

        default:
            return state;
    }
}

export function addItemToCartAction(item) {
    return (dispatch) => {
        return dispatch({
            type: 'ADD_TO_CART',
            item
        });
    };
}

export function removeItemFromCartAction(item) {
    return {
        type: 'REMOVE_FROM_CART',
        item
    };
}

