import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Navigation from '../Navigation/Navigation';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Checkout.scss';
import { addApmIframes } from './CheckoutRedux';
import * as config from '../../config/config';
import { addCardSaveIframes } from './CheckoutRedux';

export class Checkout extends Component {
    static propTypes = {
        checkout: PropTypes.any,
        cart: PropTypes.any,
        addApmIframes: PropTypes.func,
        addCardSaveIframes: PropTypes.func
    }

    getApmIframeLinks = async () => {
        const items = _.toArray(_.get(this, 'props.cart.items'));
        if (_.size(items)) {
            let apmBody = {
                data: {
                    currency: 'USD',
                    amount: _.get(this, 'props.cart.amount', 0),
                    customer: {
                        firstName: 'Timmy',
                        lastName: 'Turner',
                        email: 'timmyistheturner@timmy.turner',
                        orderNumber: Math.floor(Math.random() * 10000),
                        billToAddressOne: '107 Shoalford Dr',
                        billToCity: 'Huntsville',
                        billToState: 'AL',
                        billToPostal: '35806',
                        billToCountry: 'US',
                        billToPhone: '8015551234'
                    },
                    cart: {
                        items
                    }
                }
            };

            const apmResponse = await fetch(`${config.default.mockRetailUrl}/apm/iframes`, {
                method: 'POST',
                body: JSON.stringify(apmBody)
            });
            const apmIframes = await apmResponse.json();
            this.props.addApmIframes(apmIframes);

            return { apmIframes };
        }
    }

    getCardSaveIframe = async () => {
        const items = _.toArray(_.get(this, 'props.cart.items'));
        let body = {
            data: {
                currency: 'USD',
                amount: _.get(this, 'props.cart.amount', 0),
                cart: {
                    items
                }
            },
            uiOptions: {
                displaySubmitButton: true
            }
        };

        const tokenResponse = await fetch(`${config.default.mockRetailUrl}/card/save`, {
            method: 'POST',
            body: JSON.stringify(body)
        });

        const json = await tokenResponse.json();
        const iFrameUrl = _.get(json, 'message');
        this.props.addCardSaveIframes(iFrameUrl);

        return { iFrameUrl };
    }

    render() {
        if (_.size(_.get(this, 'props.checkout')) <= 1) {
            this.getCardSaveIframe();
            this.getApmIframeLinks();
        }

        return (
            <div className="checkout">
                <Navigation pageTitle="CHECKOUT" />
                <div className="payment-methods">
                    {this.renderCardSaveIframe()}
                    {this.renderApmButtons()}
                </div>
                <div className="checkout-items">
                    {this.renderNavButtons()}
                </div>
            </div>
        );
    }

    renderNavButtons = () => {
        return (
            <div className="checkout-total">
                <NavLink exact to="/items"><button>Back to Menu</button></NavLink>
                <NavLink exact to="/cart"><button>Back to Cart</button></NavLink>
            </div>
        );
    }

    renderApmButtons = () => {
        const iframes = _.get(this, 'props.checkout.apmIframes');

        return (
            <div className="apm-buttons">
                {_.map(iframes, (iframe) => {
                    return (
                        <iframe
                            className="apm-iframes"
                            key={_.get(iframe, 'paymentMethod')}
                            title={_.get(iframe, 'paymentMethod')}
                            src={_.get(iframe, 'url')}
                        />
                    );
                })}
            </div>
        );
    }

    renderCardSaveIframe = () => {
        return (
            <div className="card-save-form">
                <iframe
                    title="saveCardIframe"
                    id="saveCardIframe"
                    style={{ backgroundColor: 'white', height: '300px' }}
                    src={this.props.checkout.cardSaveIframes}
                />
            </div>
        );
    }

}

export function mapStateToProps(state) {
    return {
        cart: state.cart,
        iframes: state.iframes,
        checkout: state.checkout
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        addApmIframes: (apmIframes) => dispatch(addApmIframes(apmIframes)),
        addCardSaveIframes: (cardSaveIframes) => dispatch(addCardSaveIframes(cardSaveIframes))
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));