/* eslint-disable no-case-declarations */
import _ from 'lodash';

export function checkoutReducer(state = {}, action = {}) {
    switch (_.get(action, 'type')) {
        case 'ADD_APM_IFRAMES':
            return {
                ...state,
                apmIframes: _.get(action, 'iframes')
            };

        case 'ADD_CARD_SAVE_IFRAMES':
            return {
                ...state,
                cardSaveIframes: _.get(action, 'iframes')
            };
        default:
            return state;
    }
}

export function addApmIframes(iframes) {
    return (dispatch) => {
        return dispatch({
            type: 'ADD_APM_IFRAMES',
            iframes
        });
    };
}

export function addCardSaveIframes(iframes) {
    return (dispatch) => {
        return dispatch({
            type: 'ADD_CARD_SAVE_IFRAMES',
            iframes
        });
    };
}
