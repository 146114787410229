import api from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../node_modules/css-loader/index.js??ref--4-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/sass-loader/dist/cjs.js??ref--4-oneOf-4-3!./Items.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};