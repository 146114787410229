import React from 'react';
import Items from '../Items/Items';
import Cart from '../Cart/Cart';
import Home from '../Home/Home';
import Checkout from '../Checkout/Checkout';
import {
    Route,
    Switch
} from 'react-router-dom';

const Routes = () => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/items" component={Items} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/checkout" component={Checkout} />
    </Switch>
);

export default Routes;
