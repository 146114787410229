import './shared.scss';
import App from './App/App';
import { Provider } from 'react-redux';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import store from './store';

import '../public/favicon.ico';

class ConnectedApp extends Component {
    render() {
        return (
            <Provider store={store}>
                <App />
            </Provider>
        );
    }
}

ReactDOM.render(<ConnectedApp />, document.getElementById('root'));
