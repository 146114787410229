/* eslint-disable max-len */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import './Home.scss';

export class Home extends Component {
    render() {
        return (
            <div className="home">
                <Navigation pageTitle="DEMO CAFE" />
                <div className="home-body">
                    <div className="home-text">
                        <p>Viral slow-carb wolf, cornhole vice polaroid brooklyn narwhal shoreditch af hot chicken truffaut skateboard succulents kinfolk. Kombucha franzen chicharrones fanny pack kitsch. 90&apos;s poutine godard, thundercats slow-carb tousled vexillologist listicle fixie celiac kickstarter. Meggings artisan narwhal chartreuse seitan roof party kogi whatever VHS master cleanse lomo. Retro kitsch copper mug church-key tumeric put a bird on it farm-to-table cornhole coloring book live-edge. Lo-fi retro bushwick, poutine ennui edison bulb jianbing tattooed fanny pack trust fund.</p>
                        <p>Vegan bushwick unicorn crucifix, coloring book celiac pitchfork venmo sartorial. Banjo brunch poutine kinfolk taiyaki, hashtag meh master cleanse sriracha. Etsy put a bird on it listicle unicorn tilde small batch, chartreuse jianbing pour-over brooklyn mumblecore selfies retro sustainable green juice. Hexagon viral tacos jean shorts, bespoke yr fashion axe godard air plant occupy umami.</p>
                    </div>
                    <div className="explore-menu">
                        <NavLink exact to="/items"><button>Explore Menu</button> </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect()(Home));